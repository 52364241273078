import 'moment/locale/ja'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import axiosJsonp from 'axios-jsonp'
import moment from 'moment'

import DatePicker from 'components/DatePicker'
import CouponCodeInput from 'components/CouponCodeInput'
import CustomButton from 'components/CustomButton'
import CustomSelect from 'components/CustomSelect'
import CustomSelectHidden from 'components/CustomSelectHidden'
import CustomTextField from 'components/CustomTextField'
import OptionalForm from 'components/OptionalForm'
import Popup from 'components/Popup'
import SectionHeader from 'components/SectionHeader'
import { prefectures, sexes } from 'config'
import { useAPI } from 'hooks/useAPI'
import { useEvent } from 'hooks/useEvent'

const validateFormValue = data => {
  if (data.postal_code) {
    data.postal_code = validatePostalCode(data.postal_code).value
  }
  if (data.phone_number) {
    data.phone_number = validatePhoneNumber(data.phone_number).value
  }
  return data
}

const validatePhoneNumber = value => {
  const trimmed = value.replace(/-/g, '')
  const formatted = trimmed.replace(/[０-９]/g, str =>
    String.fromCharCode(str.charCodeAt(0) - 65248)
  )
  const valid = /[0-9]{10,11}/.test(formatted) && [10, 11].includes(formatted.length)

  return { valid, value: formatted }
}

const validatePostalCode = value => {
  const trimmed = value.replace(/-/g, '')
  const formatted = trimmed.replace(/[０-９]/g, str =>
    String.fromCharCode(str.charCodeAt(0) - 65248)
  )
  const valid = /[0-9]{7}/.test(formatted) && formatted.length === 7

  return { valid, value: formatted }
}

export default function Entry() {
  const { id } = useParams()
  const { getEvent } = useEvent()
  const { participantResources, getParticipants } = useEvent()
  const { push } = useHistory()
  const [event, setEvent] = useState(null)
  const { palette, spacing } = useTheme()
  const { client, requesting } = useAPI()
  const [error, setError] = useState(null)
  const { register, control, handleSubmit, errors, setValue, watch } = useForm()
  const [coupon, setCoupon] = useState(null)
  const price = useMemo(() => {
    if (event === null || event.use_payment === false) return null
    if (coupon !== null) return coupon.discount_price
    return event.price
  }, [coupon, event])
  const postalCode = watch('postal_code')
  const [openLastConfirm, setOpenLastConfirm] = useState(false)

  useEffect(() => {
    if (postalCode && validatePostalCode(postalCode).valid) {
      const code = String(validatePostalCode(postalCode).value)
      axios
        .get('https://map.yahooapis.jp/search/zip/V1/zipCodeSearch', {
          params: {
            appid: process.env.REACT_APP_YAHOO_APP_ID,
            query: `${code.substr(0, 3)}-${code.substr(3, 7)}`,
            output: 'json',
            detail: 'full',
          },
          adapter: axiosJsonp,
        })
        .then(res => {
          if (res.data && res.data.Feature && res.data.Feature.length > 0) {
            const property = res.data.Feature[0].Property
            setValue('prefectures_id', property.GovernmentCode.substr(0, 2))
            setValue(
              'address1',
              property.AddressElement.filter(x => x.Level !== 'prefecture')
                .map(x => x.Name)
                .join('')
            )
          }
        })
    }
  }, [postalCode, setValue])

  useEffect(() => {
      getParticipants()
  }, [getParticipants])

  const onSubmit = useCallback(
    data => {
      event.optional_forms.forEach((field, i) => {
        if (data[`optional_form_value_${i + 1}`] === '') {
          delete data[`optional_form_value_${i + 1}`]
        }
      })
      // Formの値のValidate
      data = validateFormValue(data)
      data.event_id = id
      // クーポンで無料になったとき
      if (event.use_payment) {
        data.payment_token = null
        data.coupon_code = coupon.code
      }
      // 住所入力欄がある場合に address1,address2 形式に整形
      if (event.use_address) {
        data.address = `${data.address1},${data.address2}`
        delete data.address1
        delete data.address2
      }

      client
        .post('/vrwc/event_participant', data)
        .then(() => push(`/events/${id}/entry/complete`))
        .catch(setError)
    },
    [client, id, push, event, coupon]
  )

  const onPayment = useCallback(
    data => {
      event.optional_forms.forEach((field, i) => {
        if (data[`optional_form_value_${i + 1}`] === '') {
          delete data[`optional_form_value_${i + 1}`]
        }
      })
      // Formの値のValidate
      data = validateFormValue(data)
      data.event_id = id
      if (coupon) {
        data.coupon_code = coupon.code
      }
      // 住所入力欄がある場合に address1,address2 形式に整形
      if (event.use_address) {
        data.address = `${data.address1},${data.address2}`
        delete data.address1
        delete data.address2
      }
      let handler = window.Komoju.multipay.configure({
        key: process.env.REACT_APP_KOMOJU_PUBLIC_KEY,
        token: function (token) {
          client
            .post('/vrwc/event_participant', { ...data, event_id: id, payment_token: token.id })
            .then(() => push(`/events/${id}/entry/complete`))
            .catch(setError)
        },
      })

      handler.open({
        amount: price,
        locale: 'ja',
        currency: 'JPY',
        title: event.title,
        description: 'イベント参加費用',
        methods: event.paymentMethod,
      })
    },
    [client, id, push, event, price, coupon]
  )

  const onLastParticipant = () => {
      if (last) {
          client
              .get(`/vrwc/event_participant/${last.participant.id}`)
              .then(res => {
                  const lastParticipant = res.data.participant
                  setValue("user_name", lastParticipant.name)
                  setValue("birthday", lastParticipant.birthday.split(' ')[0])
                  setValue("email", lastParticipant.email)
                  setValue("sex", lastParticipant.sex)
                  setValue("postal_code", lastParticipant.postal_code)
                  setValue("prefectures_id", lastParticipant.prefecture_id)
                  setValue("address1", lastParticipant.address.split(',')[0])
                  setValue("address2", lastParticipant.address.split(',')[1])
                  setValue("phone_number", lastParticipant.phone_number)
                  setValue("optional_form_value_1", lastParticipant.optional_form_value_1)
              })
      } else {
          setOpenLastConfirm(true)
      }
  }

  useEffect(() => {
    getEvent(id).then(eventResource => setEvent(eventResource.event))
  }, [id, getEvent])

  if (!event) {
    return null
  }

  const last = participantResources[0] ?? ""

  return (
    <div style={{ background: palette.background.paper }}>
      <SectionHeader
        primary={event.title}
        secondary={moment(event.start_time).format('YYYY/MM/DD')}
      />
      <div style={{ padding: spacing(3) }}>
        {Boolean(process.env.REACT_APP_ACCOUNT_ID === '1') && (
            <CustomButton style={{ marginBottom: spacing(6) }} disabled={requesting} onClick={onLastParticipant}>
                前回のエントリー情報を利用する
            </CustomButton>
        )}
        <CustomTextField
          id="user_name"
          label="お名前(フルネーム)"
          register={register({ required: '必須項目です' })}
          placeholder="本名で入力してください"
          error={errors.user_name}
        />
        <DatePicker
          id="birthday"
          label="生年月日"
          control={control}
          rules={{ required: '必須項目です' }}
          error={errors.birthday}
        />
        <CustomTextField
          id="email"
          label="メールアドレス"
          register={register({
            required: '必須項目です',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'メールアドレスの形式で入力してください',
            },
          })}
          placeholder="example@sample.com"
          error={errors.email}
        />
        {process.env.REACT_APP_ACCOUNT_ID === '25' && (
            <CustomSelectHidden
                id="sex"
                label="性別"
                control={control}
                rules={{required: '必須項目です'}}
                options={sexes}
                error={errors.sex}
            />
        )}
        {process.env.REACT_APP_ACCOUNT_ID !== '25' && (
        <CustomSelect
          id="sex"
          label="性別"
          control={control}
          rules={{ required: '必須項目です' }}
          options={sexes}
          error={errors.sex}
        />
        )}
        {event.use_postal_code && (
          <CustomTextField
            id="postal_code"
            label="郵便番号"
            register={register({
              validate: v => validatePostalCode(v).valid,
            })}
            placeholder="1500031"
            error={errors.postal_code && { message: '入力形式が不正です' }}
          />
        )}
        {process.env.REACT_APP_ACCOUNT_ID === '25' && (
            <CustomSelectHidden
                id="prefectures_id"
                label={event.use_address ? '都道府県' : '居住地'}
                control={control}
                rules={{ required: '必須項目です' }}
                options={prefectures}
                error={errors.prefectures_id}
            />
        )}
        {process.env.REACT_APP_ACCOUNT_ID !== '25' && (
            <CustomSelect
                id="prefectures_id"
                label={event.use_address ? '都道府県' : '居住地'}
                control={control}
                rules={{ required: '必須項目です' }}
                options={prefectures}
                error={errors.prefectures_id}
            />
        )}
        {event.use_address && (
          <>
            <CustomTextField
              id="address1"
              label="市区町村〜番地"
              register={register({ required: '必須項目です' })}
              placeholder="渋谷区桜丘町23番17号"
              error={errors.address1}
            />
            <CustomTextField
              id="address2"
              label="建物名・部屋番号"
              register={register}
              placeholder="シティコート桜丘408"
              helperText="上記に入力していただいた住所は、賞品が当選した場合の配送先としてのみ使用します。"
            />
          </>
        )}
        {event.use_phone_number && (
          <CustomTextField
            id="phone_number"
            label="電話番号"
            register={register({
              validate: v => validatePhoneNumber(v).valid,
            })}
            placeholder="0368693154"
            error={errors.phone_number && { message: '入力形式が不正です' }}
          />
        )}
        {event.optional_forms.map((field, i) => (
          <OptionalForm
            key={field.label}
            id={`optional_form_value_${i + 1}`}
            control={control}
            register={register}
            field={field}
            error={errors[`optional_form_value_${i + 1}`]}
          />
        ))}
        {event.use_payment && <CouponCodeInput onSuccess={setCoupon} />}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: spacing(3, 3, 6),
        }}
      >
        <Typography style={{ marginBottom: spacing(3) }} variant="caption">
          ※お名前は入力された通りに完走証に記載されます
        </Typography>
        {event.use_payment && price > 0 ? (
          <CustomButton disabled={requesting} onClick={handleSubmit(onPayment)}>
            イベント参加費({price}円)を支払って参加
          </CustomButton>
        ) : (
            Boolean(process.env.REACT_APP_ACCOUNT_ID === '29') ?
                <CustomButton disabled={requesting}>
                    参加人数が定員に達しました
                </CustomButton> :
                <CustomButton disabled={requesting} onClick={handleSubmit(onSubmit)}>
                    参加を申し込む
                </CustomButton>
        )}
      </div>
      <Popup
        open={error !== null}
        onClose={() => setError(null)}
        title="エラーが発生しました"
        description="イベントへの申し込みに失敗しました。"
        primaryText="OK"
        onClickPrimary={() => setError(null)}
      />
      {openLastConfirm && (
          <Popup
              open={openLastConfirm}
              onClose={() => setOpenLastConfirm(false)}
              title="エラー"
              description="前回のエントリー情報がありません"
              buttonDirection="row"
              primaryText="OK"
              onClickPrimary={() => {
                    setOpenLastConfirm(false)
                }}
          />
      )}
    </div>
  )
}
